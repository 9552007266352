import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const options = ['Option 1', 'Option 2'];

export default function SingleOptionComp(props) {
	const [value, setValue] = React.useState('');
	const [inputValue, setInputValue] = React.useState('');

	return (
		<div>
			{/* <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
			<div>{`inputValue: '${inputValue}'`}</div> */}
			{/* <br /> */}
			<Autocomplete
				value={props.storedValue ? props.storedValue : ''}
				onChange={(event, newValue) => {
					setValue(newValue);
					//console.log(newValue);
					props.getValue(props.name, newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				//id="controllable-states-demo"
				options={props.data ? props.data : ['']}
				renderInput={(params) => (
					<TextField
						margin="dense"
						variant="outlined"
						fullWidth
						{...params}
						label={props.label}
					/>
				)}
			/>
		</div>
	);
}
