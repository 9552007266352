import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
	const [open, setOpen] = React.useState(false);
	const [content, setContent] = React.useState(props.content);

	const handleClickOpen = () => {
		setOpen(true);
		getOperationResults();
	};
	const getOperationResults = async () => {
		let res = await props.operation();

		setContent(res);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
			>
				{props.buttonText}
			</Button>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					{/* <DialogContentText id="alert-dialog-slide-description"> */}
					{content.map((x, i) => (
						<p key={i}>{x}</p>
					))}
					{/* </DialogContentText> */}
				</DialogContent>
				<DialogActions>
					{/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
