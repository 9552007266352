import { Typography } from '@material-ui/core';
import React from 'react';

export default function PrivacyTextComp() {
	return (
		<div>
			<Typography variant="subtitle1" gutterBottom component="div">
				מדיניות פרטיות Honoshi
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				Honoshi (לרבות כל הישויות הקשורות אליה) ("Honoshi", "אנחנו" או "אנו")
				מחויבים להגן על פרטיות המשתמשים בכל השירותים המוצעים על ידה.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				מדיניות פרטיות זו באה כדי לספק מידע אודות האופן בו אנו מתנהלים, אוספים
				ועושים שימושים במידע שאתם מספקים לנו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				בנוסף, מדיניות זו מפרטת את האמצעים שאנו נוקטים על מנת להגן על בטיחות
				המידע ומידע נוסף על אופן יצירת קשר במידה ויש לכם שאלות כלשהן בנוגע
				למדיניות הפרטיות שלנו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				בעת השימוש בשירותים שלנו, אנו עשויים לאסוף מידע משני סוגים:
			</Typography>
			<ol>
				<li>
					מידע אישי – מידע שמאפשר לנו לזהות את המשתמש ואת פרטי הקשר שלו, כמו שם
					המועמד, מספר טלפון, כתובת דואר אלקטרוני וכדומה.
				</li>
				<li>
					מידע נוסף – מידע הכולל פרטים כלליים על המשתמש מבחינת מקומות עבודה
					קודמים, השכלה, העדפות של סוגי משרות, טווחי השתכרות רצויים וכדומה.
				</li>
			</ol>
			<Typography variant="subtitle1" gutterBottom component="div">
				סוגי מידע אלו ניתנים ונמסרים על ידי המשתמש ביודעין ובהסכמתו לטובת קבלת
				מידע ושירותים שמוצגים אצלנו או שאנו מפרסמים בדרכים שונות דוגמת פרופיל
				הלינקדאין שלנו, קבוצות לינקדאין ופייסבוק שונות, אתרי פרסום משרות שונים
				ועוד.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				על פי חוק, המשתמש לא מחויב למסור לנו את הפרטים שצוינו במידע האישי ובמידע
				הנוסף, אך על מנת לקבל את השירותי המיטבי ביותר שלנו, אנו דורשים לשתף
				עימנו את הפרטים המפורטים הללו. במידה והמשתמש מסרב למסור חלק מהמידע האישי
				והמידע הנוסף, לא מן הנמנע כי לא נוכל לספק את השירותים שלנו עבורו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				כיצד נאספים המידע האישי והנוסף?
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				תהליך ההרשמה כולל מילוי של שדות שונים הכוללים את המידע המבוקש שמאפשר לנו
				לבצע את ההתאמה הטובה ביותר. בנוסף, בחלק מהמקרים, במהלך שיחות טלפון עימנו
				נאספים פרטים נוספים שלא צוינו ואשר הינם חלק מהמידע האישי או הנוסף, אשר
				אנו שומרים גם כן על מנת לטייב את ההתאמה בעתיד.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				יצירת קשר – במידה והמשתמש מעוניין ליצור עימנו קשר, ניתן לבצע זאת דרך
				המייל לכתובת office@honoshi.co.il ולהתקשר למספר טלפון 054-4613899
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				הצעות לייעול – אם אתם חושבים שיש משהו בתהליך, בשירות שלנו או באתר עצמו,
				נשמח לשמוע דרך פניה ישירה אלינו במייל לכתובת office@honoshi.co.il, תוך
				ציון שם, כתובת דואר אלקטרוני ומספר טלפון, נושא הפניה ואת ההצעות שבגינן
				פניתם אלינו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				מה אנו עושים למעשה עם המידע האישי והנוסף?
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו נוכל לספק את שירותי האתר שלנו ולתמוך בתהליך נאות של השירות
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו נוכל ליצור קשר עם המשתמשים
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו נוכל לשלוח הודעות הקשורות לשירותים שאנו מציעים
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				מסירת המידע האישי והנוסף של המשתמשים
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				כל משתמש מבין ומסכים כי במידת הצורך, המידע שנאסף יימסר, אם נידרש לכך על
				פי חוק או אם תהיה עילה לפיה נהיה סבורים כי המסירה של המידע נדרשת, באופן
				סביר, על מנת להימנע מחבות משפטית שלנו, על מנת לציית להליך משפטי כזה או
				אחר, ולברות, מבלי לגרוע, זימון לדין, צו חיפוש או צו בית משפט, או על מנת
				להגן על רכוש וזכויות שלנו או של צד שלישי, להגן על בטיחות הציבור או של כל
				אדם, או למנוע או לעצור פעילות שנמצא שהיא בלתי חוקית, בלתי אתית, או ברת
				תביעה או שיש סכנה שתהיה כזו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				מלבד לאמור במדיניות פרטיות זו, אנו לא נשתף את המידע האישי והנוסף של
				המשתמשים עם צדדים שלישיים למטרות שיווק ללא הרשות של המשתמשים, אלא כפי
				שמפורט בתנאי המדיניות הזו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				קבלת תנאי השימוש מהווה מתן רשות להעברת המידע לפרטיים או חברות לצורך
				בחינת העסקה פוטנציאלית.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				חשוב להדגיש כי אנו כן רשאים למכור, להעביר או לשתף באופן אחר חלק או את כל
				הנכסים שלנו, לרבות מידע אישי ומידע נוסף של המועמדים בקשר עם פעולות
				מיזוג, רכישה, ארגון מחדש או מכירה של הנכסים שלנו, או במקרה של פשיטת רגל.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו כן רשאים ועשויים לשכור שירותים מצדדים שלישיים שהינם יחידים וחברות,
				אשר יחשפו למידע על מנת לעזור בביצוע ולספק את שירותינו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				שמירת המידע של המשתמשים
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו מאחסנים את המידע האישי והנוסף שנאספים על מנת לספק את שירותינו על
				שרתים מהמובילים בתחום, שמספקים רמת אבטחה הולמת למידע. אנו אף נוקטים
				בצעדי הגנה על מנת להגן על המידע.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				במקרה בו בוצעה העברת מידע באופן רשלני, אנו ננקוט באמצעים סבירים מבחינה
				מסחרית על מנת להגביל ולתקן זאת. יחד עם זאת, אנו לא יכולים לערוב לכך
				שצדדים שלישיים לא מאושרים יצליחו לעולם לעקוף את ההליכים הללו או לעשות
				שימוש במידע כלכלי, אישי או במידע הנוסף למטרות בלתי הולמות.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				המידע הוא שלך ואתה זכאי לעיין בו
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				במידה והמשתמש מעוניין לבחון את המידע האישי והמידע הנוסף שנמצא אצלנו,
				ו/או לברר פרטים אחרים בקשר למידע זה, ניתן ליצור עימנו קשר בכתובת הדואר
				האלקטרוני הבאה: office@honoshi.co.il
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				תיקון ועדכון המידע של המשתמשים
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				כל עוד משתמש לא ביקש מאיתנו להסירו מהשירותים שלנו, אנו נשמור על המידע
				האישי והמידע הנוסף שלהם וזאת על מנת לספק את שירותינו, או היות ונדרש
				מאיתנו על מנת למלא אחר התחייבויותינו החוקיות, לפתור סכסוכים ולאכוף את
				ההסכמים שלנו.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				במידה ומשתמש מעוניין לתקן או לעדכן את הרישומים של המידע במערכת שלנו, יש
				לבצע פנייה לכתובת הדואר האלקטרוני הבאה:{' '}
				<a href="mailto:office@honoshi.co.il">office@honoshi.co.il</a>  ואנו
				נשתדל למלא את הבקשה.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				פרטיות מידע של אנשים מתחת לגיל 18
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו לא אוספים באופן מודע מידע אישי של אנשים מתחת לגיל 18 ולא מייעדים את
				השירותים שלנו מתחת לגיל האמור. אם משתמש הינו מתחת לגיל 18, אנו מבקשים כי
				הוא לא ימסור כל מידע לאתר שלנו וממליצים להורים ולאפוטרופוסים לקחת תפקיד
				פעיל בפעילויות ובאינטרסים של ילדיהם באינטרנט.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				שינויים במדיניות הפרטיות
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו שומרים לעצמנו את הזכות לשנות מדיניות פרטיות זו מעת לעת. הגרסה
				העדכנית ביותר של המדיניות היא הגרסה הקובעת לעניין השימוש שנעשה במידע
				האישי ובמידע הנוסף של כל משתמש. יחד עם זאת, ולפי שיקול דעתנו הבלעדי, אם
				נחליט כי עדכון כזה או אחר של המדיניות מהווה שינוי מהותי, אנו נודיע על
				השינויים כאמור בהודעה למשתמשים. על אף האמור לעיל, שינויים שאינם מהותיים
				לא מחויבים מבחינתנו לעדכון והודעה למשתמשים וניתן להיכנס ולבחון את
				מדיניות הפרטיות העדכנית ביותר מעת לעת באמצעות המשרות שאנו מפרסמים
				והפניות הכלליות להירשם לשירותינו שאנו מפרסמים באמצעים השונים.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				יצירת קשר
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				ככל וישנן שאלות כלשהן למשתמשים, או חשש כלשהו, בנוגע למדיניות הפרטיות
				הזו, או בכל נושא אחר הקשור לשירותים שלנו, אנא פנו אלינו בכתובת הדואר
				האלקטרוני הבאה: office@honoshi.co.il
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				עדכון אחרון נעשה בתאריך 28/09/2021
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				 
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				 
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				 
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				Honoshi תנאי שימוש
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים
				כאחד
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אתר www.honoshi.co.il (להלן: "האתר") משמש לצורך מתן שירותים בתחום איוש
				משרות לטובת ועבור מעסיקים ומועמדים פוטנציאליים. הנך מוזמן לקחת בו חלק
				בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, הורדות של קבצים,
				מדיה כגון תמונות וסרטונים והתכנים השונים המוצעים לקהל המבקרים עשויים
				להשתנות מעת לעת או בהתאם לסוג התוכן.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת
				וללא התראה או אזכור מיוחד בערוצי האתר השונים.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				קניין רוחני
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				האתר, כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות
				גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר
				מוצג באתר, שייכים במלואם לאתר הנ"ל ומהווים קניין רוחני בלעדי של אתר
				www.honoshi.co.il ואין לעשות בהם שימוש ללא אישור כתוב מראש מאתר
				www.honoshi.co.il.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				בנוסף, אין להעתיק, לשכפל, להפיץ, לפרסם, לחקות או לעבד פיסות קוד,
				גרפיקות, סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר מבלי שיש ברשותכם
				אישור כתוב מראש.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				תוכן האתר
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו בשל שיקולים
				טכניים, תקלות צד ג' או אחרים, הפרעות בזמינות האתר. לכן, איננו יכולים
				להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל
				הפסקת השירות / הורדת האתר.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים, איכותיים
				או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום האחריות
				הבלעדי של המשתמש באתר.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				התכנים המוצעים באתר הינם בבעלותם הבלעדית של www.honoshi.co.il ואין לעשות
				בהם שימוש אשר נועד את האמור בתקנון זה למעט במקרים בהם צוין אחרת או
				במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני. במקרים אלו יש
				לבדוק מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין באתר החיצוני לו
				שייכים התכנים.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				ניהול משתמשים ומבקרים באתר
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם על ידי חסימת
				כתובת ה-IP של המחשב שלו, כתובת ה-MACID של המחשב שלו או אפילו בהתאם
				למדינת המוצא ללא צורך לספק תירוץ אשר מקובל על המשתמש.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי המשתמשים הרשומים
				באתר / מנויים הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי להשיג גישה
				למידע מוסכם בזאת כי לגולשים, משתמשים וחברים באתר לא תהה כל תביעה, טענה
				או דרישה כלפי צוות האתר www.honoshi.co.il.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				גילוי נאות
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				באתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור משתמשים רשומים
				ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד סטטיסטי אנונימי של
				גולשים וניתוח תנועת הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				בכל העת ולבד מאשר גולשים המחוברים לאתר, המידע הנשמר הוא אנונימי לחלוטין
				ואין בו את שם הגולש או כל פרט מזהה אחר.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				איזור שיפוט
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחלוקת, אתם מסכימים להלן
				כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי של החוק הישראלי תוך שימוש
				במערכת בתי המשפט הישראליים בלבד במחוז תל אביב.
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				 
			</Typography>
		</div>
	);
}
