import MainComp from './BackOffice/Main';
import MainLandingPage from './LandingPageForm/MainLandingPage';
import MainLandingPageGeneric from './LandingPageForm/MainLandingPageGeneric';
import {
	Link,
	Route,
	Router,
	Switch,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import './styles.css';

import { useEffect } from 'react';
import LoginComp from './BackOffice/Login';
import {
	MuiThemeProvider,
	createMuiTheme,
	StylesProvider,
} from '@material-ui/core/styles';
import landingPageReducer from './LandingPageForm/landingPageReducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import MainPage from './mainPage/MainPage';
//import MailMedia from './mailMedia/MailMedia';
const landingStore = createStore(landingPageReducer);

export default function SwichPageComp(props) {
	let { path, url } = useRouteMatch();

	useEffect(() => {}, []);
	return (
		<div>
			<Switch>
				<Route path={`${path}backoffice`}>
					<MainComp />
				</Route>
				{/* <Route path={`${path}landing/:id`}>
					
						<MainLandingPage/>
					
				</Route> */}

				<Route path={`${path}landing/:id`} component={MainLandingPage} />
				{/* <Route path={`${path}mailmedia`} component={MailMedia} /> */}


				<Route path={`${path}landing/`} component={MainLandingPageGeneric} />
				{/* <Route path={`${path}landing/:id`}>
				<Provider store={landingStore}>
					<MainLandingPage />
					</Provider>
				</Route> */}
				<Route path={`${path}login`}>
					<LoginComp />
				</Route>

				<Route exact path={`${path}`}>
					{/* <MainPage /> */}
					{/* {process.env.NODE_ENV} //production or development*/} 
				</Route>
			</Switch>

			{/* <JobsComp /> */}
		</div>
	);
}
