import axios from 'axios';

let config = (page, searchText, fields) => {
	return {
		headers: {
			'x-access-token': sessionStorage['token'],
		},
		params: {
			page: page || null,
			searchText: searchText || null,
			fields: fields || null,
		},
	};
};

let host = 'http://localhost:8000/';
if (process.env.NODE_ENV == 'production') {
	host = 'https://honoshi-server.herokuapp.com/';
}
//console.log(host);
const url = host + 'api/candidates';

const getLimitedCandidates = () => {
	return axios.get(url + '/limited', config());
};

// const getAllCandidates = () => {
// 	return axios.get(url, config());
// };

const getAllCandidates = (page, searchText, fields) => {
	return axios.get(url, config(page, searchText, fields));
};

const getCountAllCandidates = (page, searchText, fields) => {
	return axios.get(url + '/count', config(page, searchText, fields));
};

const getDuplicates = (obj) => {
	console.log('in getDuplicates - utils');
	return axios.get(url + '/duplicates', config());
};

const getCandidate = (id) => {
	return axios.get(url + '/' + id, config());
};

const addCandidate = (obj) => {
	return axios.post(url, obj, config());
};

const addCandidateFromMail = (obj) => {
	return axios.post(url + '/addCandidateFromMail', obj, config());
};

const updateCandidate = (id, obj) => {
	return axios.put(url + '/' + id, obj, config());
};

const deleteCandidate = (id) => {
	return axios.delete(url + '/' + id, config());
};

export default {
	getLimitedCandidates,
	getAllCandidates,
	getCountAllCandidates,
	getCandidate,
	addCandidate,
	updateCandidate,
	deleteCandidate,
	addCandidateFromMail,
	getDuplicates,
};
