import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import {
	Avatar,
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Icon,
} from '@material-ui/core';
import logo from '../img/logo.jpg';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	direction: 'rtl',

	appBar: {
		position: 'relative',
	},
	// main: {
	// 	backgroundImage:
	// 		'linear-gradient(45deg, #e8e8e8 25%, #ffffff 25%, #ffffff 50%, #e8e8e8 50%, #e8e8e8 75%, #ffffff 75%, #ffffff 100%)',
	// 	backgroundSize: '56.57px 56.57px',
	// },
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			//	width: 600,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
		backgroundColor: 'transparent',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
	biggerImage: {
		height: '180px',
		width: '180px',
		margin: 'auto',
	},
}));

export default function MainPage() {
	const classes = useStyles();

	return (
		<div>
			{/* {storeData.jobData?.industryName} */}
			<main className={classes.layout}>
				{/* <Paper className={classes.paper}> */}
				<Box component="span" m={13}>
					<Box justifyContent="center" m={13} mb={3}>
						<Avatar
							className={classes.biggerImage}
							alt="honoshi"
							src={logo}
							//className={classes.large}
						/>
					</Box>

					{/* <img src={require('./logo.jpg')} /> */}
					<Box component="span" m={5}>
						<Typography component="h1" variant="h1" align="center">
							הונושי
						</Typography>
					</Box>
					<Box component="span" m={3}>
						<Typography component="h6" variant="h4" align="center">
							מתמחים בהשמה מקצועית
						</Typography>
					</Box>
					<Box component="span" m={3}>
						<Typography
							component="h6"
							variant="h6"
							align="center"
							color="secondary"
						>
							האתר שלנו בבנייה, מקווים לסיים בקרוב ולשרת אתכם
							במקצועיות ונאמנות גם בערוץ זה
						</Typography>
					</Box>
				</Box>

				{/* </Paper> */}
			</main>
		</div>
	);
}
