/* eslint-disable no-use-before-define */

import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FormControlLabel } from '@material-ui/core';
import { getThemeProps } from '@material-ui/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function TagsComp(props) {
	const [values, SetValues] = useState([]);

	const sendValues = (e, values) => {
		props.getValues(props.name, values);
	};
	return (
		<Autocomplete
			// variant="outlined"
			// color="info"
			// size="small"
			multiple
			freeSolo
			//options={props.data ? props.data?.map((option) => option) : ['']}
			options={props.data ? props.data?.map((option) => option) : ['']}
			disableCloseOnSelect
			value={props.storedValues ? props.storedValues : ['']}
			getOptionLabel={(option) => option}
			renderOption={(option, { selected }) => (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
						// label={option} // there is a incostentivity problem with getting the name of the option - prefer to use other autocomplete option
						// name={option}
					/>
					{/* {console.log(selected)} */}
					{option}
				</React.Fragment>
			)}
			onChange={(e, values) => sendValues(e, values)}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					margin="dense"
					label={props.label}
					placeholder="ניתן להוסיף טקסט חופשי"
				/>
			)}
		/>
	);
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
	{ title: 'The Shawshank Redemption', selected: true },
	{ title: 'The Godfather', selected: false },
	{ title: 'The Godfather: Part II', selected: true },
	{ title: 'The Dark Knight', selected: false },
	{ title: '12 Angry Men', selected: false },
];
