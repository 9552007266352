import { Box, Container, Icon, Typography } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { useEffect, useState } from 'react';
import jobUtils from '../../utils/jobsUtils/utils';
import { useSelector } from 'react-redux';
function JobDescriprionComp(props) {
	const storeData = useSelector((state) => state);

	useEffect(async () => {}, []);

	return (
		<div>
			<Container maxWidth="sm">
				{/* {storeData.jobData._id} */}
				<Box component="span" m={1}>
					<Box component="span">
						<Typography variant="h6" gutterBottom>
							{/* <BusinessIcon
								color="secondary"
								style={{ position: 'relative', top: '3px', marginLeft: '10px' }}
							/> */}
							מס' משרה : {storeData.jobData.jobNumber}
						
						</Typography>
						<Typography color="secondary" variant="h6" gutterBottom>
							{/* <BusinessIcon
								color="secondary"
								style={{ position: 'relative', top: '3px', marginLeft: '10px' }}
							/> */}
							{storeData.jobData.companyToPublish}
						
						</Typography>
						<br/>
							
					</Box>
					<Box component="span">
						<Typography variant="h5" gutterBottom>
							<BusinessIcon
								color="secondary"
								style={{ position: 'relative', top: '3px', marginLeft: '10px' }}
							/>
							תיאור החברה:
						</Typography>
					</Box>
					<Typography
						variant="body1"
						gutterBottom
						style={{ whiteSpace: 'pre-line' }}
					>
						{storeData.jobData?.companyDescription}
						{/* body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
						Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
						consectetur, neque doloribus, cupiditate numquam dignissimos laborum
						fugiat deleniti? Eum quasi quidem quibusdam. */}
					</Typography>
				</Box>
				<Box component="span" m={1}>
					<Box component="span">
						<Typography variant="h5" gutterBottom>
							<PermContactCalendarIcon
								color="secondary"
								style={{ position: 'relative', top: '3px', marginLeft: '10px' }}
							/>
							תיאור התפקיד:
						</Typography>
					</Box>
					<Typography
						variant="body1"
						gutterBottom
						style={{ whiteSpace: 'pre-line' }}
					>
						{storeData.jobData?.jobDescriprion}

						{/* body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
						Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
						consectetur, neque doloribus, cupiditate numquam dignissimos laborum
						fugiat deleniti? Eum quasi quidem quibusdam. */}
					</Typography>
				</Box>
				<Box component="span" m={1}>
					<Box component="span">
						<Typography variant="h5" gutterBottom>
							<WorkIcon
								color="secondary"
								style={{ position: 'relative', top: '3px', marginLeft: '10px' }}
							/>
							דרישות תפקיד:
						</Typography>
					</Box>
					<Typography
						variant="body1"
						gutterBottom
						style={{ whiteSpace: 'pre-line' }}
					>
						{storeData.jobData?.jobRequirements}

						{/* body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
						Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
						consectetur, neque doloribus, cupiditate numquam dignissimos laborum
						fugiat deleniti? Eum quasi quidem quibusdam. */}
					</Typography>
				</Box>
			</Container>
		</div>
	);
}

export default JobDescriprionComp;
